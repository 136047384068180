import React, { FunctionComponent } from 'react'
import { Post } from '../utils/models'
import { Grid } from './Common'
import { Card } from './Card'
import formatDate from '../utils/formatDate'

interface PostGridProps {
  posts: Post[]
}

export const PostGrid: FunctionComponent<PostGridProps> = ({ posts }) => (
  <Grid>
    {posts.map((post, index) => (
      <Card
        title={post.frontmatter.title}
        path={post.frontmatter.path}
        featuredImage={
          post.frontmatter.featuredImage
            ? post.frontmatter.featuredImage.childImageSharp.gatsbyImageData
            : null
        }
        content={post.frontmatter.excerpt}
        key={index}
        meta={{
          time: post.frontmatter.created,
          timePretty: formatDate(post.frontmatter.createdPretty),
          tag:
            post.frontmatter.tags && post.frontmatter.tags.length > 0
              ? post.frontmatter.tags[0]
              : null,
        }}
      />
    ))}
  </Grid>
)
